<template>
  <v-button
    v-if="appConfig.VueSettingsPreRun.ElementButtonEffect === 'EmynArnen'"
    :id="id"
    :class-name="classNameComputed"
    :data-test-id="dataTestId"
    :disabled="disabled || loading"
    :inert="disabled || loading"
    :label="label.toString()"
    @click="localClickEvent"
  >
    <slot>
      {{ label }}
    </slot>
  </v-button>
  <template v-else>
    <!--eslint-disable-next-line vue/no-restricted-html-elements-->
    <button
      :id="id"
      :aria-label="label.toString()"
      :class="classNameComputed"
      :data-test-id="dataTestId"
      :disabled="disabled || loading"
      :inert="disabled || loading"
      @click="localClickEvent"
    >
      <slot>
        {{ label }}
      </slot>
    </button>
  </template>
</template>

<script setup lang="ts">
import type { UIButtonSize } from '~types/props'

import { VButton, type VElement } from '@arora/common'

const {
  className,
  clickEvent,
  color,
  dataTestId,
  label,
  size,
  square = false,
  type = 'fill',
  width = 'content'
} = defineProps<
  VElement & {
    clickEvent: () => Promise<unknown>
    color: 'grey' | 'primary' | 'primary-opacity' | 'secondary'
    type?: 'fill' | 'text-with-button'
    size: UIButtonSize
    square?: boolean
    width?: 'content' | 'wide'
  }
>()

const classNameComputed = computed<string>(() => {
  let result = [
    'v-ui-button',
    `v-ui-button--${type} v-ui-button-color--${color} v-ui-button-size--${size}`
  ]

  if (className) {
    result.push(className)
  }

  if (square) {
    result.push('v-ui-button-square')
  } else {
    result.push(`v-ui-button-width--${width}`)
  }

  return result.join(' ')
})
const loading = ref<boolean>(false)

function localClickEvent(): void {
  loading.value = true
  clickEvent().then(() => (loading.value = false))
}
const appConfig = useAppConfig()
</script>

<style lang="scss">
@use '~/assets/mixins';
@use '~/assets/variables';

.v-ui-button {
  @include mixins.trans;

  outline: none;
  box-shadow: none;
  cursor: pointer;
  border: none;
  text-decoration: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  font-style: normal;
  font-weight: 500;
  line-height: 1.145rem;
  padding-inline: 0;

  &:focus,
  &:active,
  &:visited {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    outline: none;
  }

  &.v-ui-button-width--content {
    width: auto;
  }

  &.v-ui-button-width--wide {
    width: 100%;
  }

  &--text-with-button {
    background: none;

    .disabled,
    :disabled {
      pointer-events: none;
      opacity: 1;
      color: variables.$Mono400;
    }

    /*#region button colors*/
    &.v-ui-button-color--primary {
      color: variables.$PrimaryBackgroundColor;

      &:hover {
        color: variables.$PrimaryBackgroundColorHover;
      }

      &:focus,
      &:active,
      &:visited {
        color: variables.$PrimaryBackgroundColorPressed;
      }
    }

    &.v-ui-button-color--secondary {
      color: variables.$SecondaryBackgroundColor;

      &:hover {
        color: variables.$SecondaryBackgroundColorHover;
      }

      &:focus,
      &:active,
      &:visited {
        color: variables.$SecondaryBackgroundColorPressed;
      }
    }

    &.v-ui-button-color--grey {
      color: variables.$Mono300;

      &:hover {
        color: variables.$Mono200;
      }

      &:focus,
      &:active,
      &:visited {
        color: variables.$Mono400;
      }
    }
    /*#endregion*/

    /*#region button sizes*/
    &.v-ui-button-size--16 {
      font-size: 1.145rem;
    }
    &.v-ui-button-size--14 {
      font-size: 1rem;
    }
    &.v-ui-button-size--12 {
      font-size: 0.85rem;
    }
    /*#endregion*/
  }

  &--fill {
    border-radius: variables.$BorderRadiusInput;

    .disabled,
    :disabled {
      pointer-events: none;
      opacity: 1;
      color: variables.$White;
      background-color: variables.$Mono300;
    }

    /*#region button colors*/
    &.v-ui-button-color--grey {
      background: variables.$Mono300;
      color: variables.$White;

      &:hover {
        background: variables.$Mono200;
        color: variables.$White;
      }

      &:focus,
      &:active,
      &:visited {
        background: variables.$Mono400;
        color: variables.$White;
      }
    }

    &.v-ui-button-color--primary {
      background: variables.$PrimaryBackgroundColor;
      color: variables.$PrimaryColor;

      &:hover {
        background: variables.$PrimaryBackgroundColorHover;
      }

      &:focus,
      &:active,
      &:visited {
        background: variables.$PrimaryBackgroundColorPressed;
      }
    }

    &.v-ui-button-color--secondary {
      background: variables.$SecondaryBackgroundColor;
      color: variables.$SecondaryColor;

      &:hover {
        background: variables.$SecondaryBackgroundColorHover;
      }

      &:focus,
      &:active,
      &:visited {
        background: variables.$SecondaryBackgroundColorPressed;
      }
    }

    &.v-ui-button-color--primary-opacity {
      background: variables.$PrimaryBackgroundColorButtonLight;
      color: variables.$PrimaryBackgroundColor;

      &:hover {
        background: variables.$PrimaryBackgroundColorButtonLightHover;
      }

      &:focus,
      &:active,
      &:visited {
        background: variables.$PrimaryBackgroundColor;
        color: variables.$PrimaryColor;
      }
    }
    /*#endregion*/

    /*#region button sizes*/
    &.v-ui-button-size--24 {
      height: 1.715rem;
      padding: 0.715rem 0.85rem;
      font-size: 0.85rem;

      &.v-ui-button-square {
        width: 1.715rem;
      }
    }
    &.v-ui-button-size--32 {
      height: 2.285rem;
      padding: 0.715rem 0.85rem;
      font-size: 0.85rem;

      &.v-ui-button-square {
        width: 2.285rem;
      }
    }
    &.v-ui-button-size--36 {
      height: 2.57rem;
      padding: 0.715rem 1.285rem;
      font-size: 1rem;

      &.v-ui-button-square {
        width: 2.57rem;
      }
    }
    &.v-ui-button-size--44 {
      height: 3.145rem;
      padding: 0.715rem 1.285rem;
      font-size: 1.145rem;

      &.v-ui-button-square {
        width: 3.145rem;
      }
    }
    &.v-ui-button-size--48 {
      height: 3.43rem;
      padding: 0.715rem 1.715rem;
      font-size: 1.285rem;

      &.v-ui-button-square {
        width: 3.43rem;
      }
    }
    /*#endregion*/
  }
}
</style>
